import React from 'react';
import Layout, { TEMPLATE } from '../components/Layout';

const title = 'Artwork';

const ArtPage = () => (
  <Layout title={title} template={TEMPLATE.GALLERY} children={null} />
);

export default ArtPage;
